<template>
  <iq-button
    :icon="icon"
    color="main-dark"
    size="fullWidthMedium"
    :class="[styles, { disabled: disabled }]"
    style="max-width: 100%; width: 100%"
    @onClick="clicked"
  >
    {{ title }}
  </iq-button>
</template>

<script>
import IqButton from '@/views/ui/components/buttons/IqButton'
export default {
  name: 'BottomToolbarButton',
  components: { IqButton },
  props: {
    styles: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'DriverPlus',
    },
  },
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit('button-clicked')
      }
    },
  },
}
</script>
